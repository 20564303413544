.ticketInbox {
    display: block;
}


.ticketInbox h1 {
    font-size: 1rem;
}

.ticketInbox h2 {
    font-size: 0.9rem;
}

.ticketInbox h3 {
    font-size: 0.8rem;
}

.ticketInbox ol {
    list-style: decimal;
}

.ticketInbox ul {
    list-style: circle;
}

.ticketInbox ol>li>a:visited {
    color: #539ed7
}

.ticketInbox>ol>li>a {
    text-decoration: underline;
}

.ticketInbox > p > a:visited {
    color: #539ed7
}

.ticketInbox > p > a {
    text-decoration: underline;
}