/*  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    ::-webkit-scrollbar {
      width: 4px; 
      height: 4px; 
    }
  }  */

 .ql-toolbar {
  position: -webkit-sticky !important; 
  position: sticky !important;
  top: 0px !important;
  z-index: 10 !important; 
   background-color: white;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important
}

.dark .ql-toolbar {
background-color: #1F2937;
} 

.ql-editor ol > li,
.ql-editor ul > li {
  list-style-type: none;
  line-height: 25px
}

.gradient-border {
  position: relative;
}

.gradient-border::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3px; /* Puedes ajustar el grosor del borde aquí */
  background: linear-gradient(to right, #f7a511, #8f4b97);
}

.gradient-border-header {
  content: '';
}

.gradient-border-header::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3px; /* Puedes ajustar el grosor del borde aquí */
  background: linear-gradient(to right, #f7a51180, #8f4b9780);
}

